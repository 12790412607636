import { makeObservable, observable, computed, action, keys } from "mobx";
import root from "../../store";
import { get, set, sortBy, orderBy } from "lodash";
import { CmdComment } from "./cmd-comment";
import { TbItmStatus, TbItmSurfaces } from "./tb-itm";

export const NeedVAO = {
    YES: "y",
    NO: "n",
    UNK: "?",
};

const _mkTool = (key, prop) => ({ key, prop });

export const CmdTools = {
    GARDURI: _mkTool("g", "garduri"),
    TALPI: _mkTool("t", "talpi"),
    BARCI: _mkTool("b", "barci"),
    LUMINI: _mkTool("l", "lumini"),
    SEMNE: _mkTool("s", "semne"),
    PLACI_OTEL: _mkTool("p", "placiOtel"),
};

export const getCmdToolsOrdered = () => {
    return [
        CmdTools.GARDURI,
        CmdTools.TALPI,
        CmdTools.BARCI,
        CmdTools.LUMINI,
        CmdTools.SEMNE,
        CmdTools.PLACI_OTEL,
    ];
};

const CmdToolsLbls = {};

export const getCmdToolLbl = (cmdToolKey, t) => {
    let _lbl = CmdToolsLbls[cmdToolKey];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tools.${cmdToolKey}`);
    CmdToolsLbls[cmdToolKey] = _lbl;

    return _lbl;
};

export class Cmd {
    _id = "";
    _sts = 10;
    _ctrIdent = "";
    _fullAdrs = "";
    _adrsInpTyp = "ADRS";
    _comment = "";

    _street = null;
    _nr = null;
    _city = null;
    _zip = null;

    _lat = "";
    _lng = "";
    _needVAO = NeedVAO.UNK;
    _region = "HB";

    _clnt = 3; // DTAG/PTI21
    _clntAspId = null;

    _dispoUsrId = null;
    _billingUsrId = null;

    _bfName = null;
    _bfMail = null;
    _bfTel = null;

    _typ = 1;
    _tb = "?";
    _m = "?";
    _cu = "n";
    _fo = "n";
    _apl = "n";
    _gf = "n";
    _eingang = "";

    _aaz = null;
    _faz = null;
    _rb = null;

    _sync = [];
    _tools = {};
    _comments = {};

    _fullLoadStarted = null;
    _lastLoad = null;

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _sts: observable,
            _ctrIdent: observable,
            _adrsInpTyp: observable,
            _fullAdrs: observable,
            _comment: observable,

            _street: observable,
            _nr: observable,
            _city: observable,
            _zip: observable,

            _lat: observable,
            _lng: observable,
            _needVAO: observable,
            _region: observable,

            _clnt: observable,
            _clntAspId: observable,

            _billingUsrId: observable,
            _dispoUsrId: observable,

            _typ: observable,
            _tb: observable,
            _m: observable,
            _fo: observable,
            _apl: observable,
            _gf: observable,
            _cu: observable,
            _eingang: observable,

            _aaz: observable,
            _faz: observable,
            _rb: observable,

            _sync: observable,
            _tools: observable,
            _comments: observable,

            _bfName: observable,
            _bfMail: observable,
            _bfTel: observable,

            _fullLoadStarted: observable,
            _lastLoad: observable,

            id: computed,
            sts: computed,
            ctrIdent: computed,
            adrsInpTyp: computed,
            fullAdrs: computed,
            comment: computed,

            street: computed,
            nr: computed,
            city: computed,
            zip: computed,

            lat: computed,
            lng: computed,
            needVAO: computed,
            region: computed,
            clnt: computed,
            clntAspId: computed,

            billingUsrId: computed,
            dispoUsrId: computed,

            typ: computed,
            tb: computed,
            m: computed,
            gf: computed,
            cu: computed,
            apl: computed,
            fo: computed,
            eingang: computed,

            aaz: computed,
            faz: computed,
            rb: computed,

            sync: computed,

            bfName: computed,
            bfMail: computed,
            bfTel: computed,

            garduri: computed,
            talpi: computed,
            barci: computed,
            lumini: computed,
            semne: computed,
            placiOtel: computed,

            toolsComment: computed,

            myVaoReqs: computed,
            myVaoIntvs: computed,

            myTbItms: computed,
            myVaos: computed,

            totalFS: computed,
            totalTS: computed,

            comments: computed,
            commentTss: computed,

            fullLoadStarted: computed,
            lastLoad: computed,

            setSts: action.bound,
            setFullAdrs: action.bound,
            setCtrIdent: action.bound,

            setTb: action.bound,
            setMn: action.bound,

            setCu: action.bound,
            setGf: action.bound,
            setApl: action.bound,
            setFo: action.bound,
            setNeedVAO: action.bound,

            setRegion: action.bound,
            setClnt: action.bound,
            setTyp: action.bound,
            setEingang: action.bound,

            setGarduri: action.bound,
            setTalpi: action.bound,
            setBarci: action.bound,
            setLumini: action.bound,
            setSemne: action.bound,
            setPlaciOtel: action.bound,

            setToolsComment: action.bound,
            setFullLoadStarted: action.bound,

            fromSrv: action.bound,
            fromAdrsPatch: action.bound,
            upsertComment: action.bound,
        });
    }

    get id() {
        return this._id;
    }
    get sts() {
        return this._sts;
    }
    get ctrIdent() {
        return this._ctrIdent;
    }
    get fullAdrs() {
        return this._fullAdrs;
    }
    get adrsInpTyp() {
        return this._adrsInpTyp;
    }
    get comment() {
        return this._comment;
    }

    get street() {
        return this._street;
    }
    get nr() {
        return this._nr;
    }
    get zip() {
        return this._zip;
    }
    get city() {
        return this._city;
    }

    get lat() {
        return this._lat;
    }
    get lng() {
        return this._lng;
    }
    get needVAO() {
        return this._needVAO;
    }
    get region() {
        return this._region;
    }
    get clnt() {
        return this._clnt;
    }
    get clntAspId() {
        return this._clntAspId;
    }

    get billingUsrId() {
        return this._billingUsrId;
    }
    get dispoUsrId() {
        return this._dispoUsrId;
    }

    get typ() {
        return this._typ;
    }
    get tb() {
        return this._tb;
    }
    get m() {
        return this._m;
    }
    get fo() {
        return this._fo;
    }
    get gf() {
        return this._gf;
    }
    get apl() {
        return this._apl;
    }
    get cu() {
        return this._cu;
    }
    get eingang() {
        return this._eingang;
    }

    get aaz() {
        return this._aaz;
    }
    get faz() {
        return this._faz;
    }
    get rb() {
        return this._rb;
    }

    get sync() {
        return this._sync;
    }

    get bfName() {
        return this._bfName;
    }
    get bfMail() {
        return this._bfMail;
    }
    get bfTel() {
        return this._bfTel;
    }

    get lastLoad() {
        return this._lastLoad;
    }
    get fullLoadStarted() {
        return this._fullLoadStarted;
    }

    get garduri() {
        return get(this, ["_tools", CmdTools.GARDURI.key], 0);
    }
    get talpi() {
        return get(this, ["_tools", CmdTools.TALPI.key], 0);
    }
    get barci() {
        return get(this, ["_tools", CmdTools.BARCI.key], 0);
    }
    get lumini() {
        return get(this, ["_tools", CmdTools.LUMINI.key], 0);
    }
    get semne() {
        return get(this, ["_tools", CmdTools.SEMNE.key], 0);
    }
    get placiOtel() {
        return get(this, ["_tools", CmdTools.PLACI_OTEL.key], 0);
    }
    get toolsComment() {
        return this._tools?.com || "";
    }

    get myVaoReqs() {
        const _myVaoReqIds = root.cmdVaoReqs[this._id] || [];
        const _myVaoReqs = _myVaoReqIds
            .map((id) => root.vaoReqs[id])
            .filter((it) => !!it);

        return sortBy(_myVaoReqs, ["dt"]);
    }

    get myVaoIntvs() {
        const _myVaoIntvIds = root.cmdVaoIntvs[this._id] || [];
        const _myVaoIntvs = _myVaoIntvIds
            .map((id) => root.vaoIntvs[id])
            .filter((it) => !!it);

        return sortBy(_myVaoIntvs, ["start"]);
    }

    get myTbItms() {
        const _myTbItmIds = root.cmdTbItms[this._id] || [];
        const _myTbItms = _myTbItmIds.map((id) => root.tbItms[id]);

        return sortBy(_myTbItms, ["typ", "nr", "desc"]);
    }

    get myVaos() {
        const _myVaoIds = root.cmdVaos[this._id] || [];
        const _myVaos = _myVaoIds.map((id) => root.vaos[id]);

        return orderBy(_myVaos, ["reqDt"], ["desc"]);
    }

    get totalTS() {
        let _total = 0;

        for (const tbItm of this.myTbItms) {
            if (tbItm.surface !== TbItmSurfaces.ASPHALT) {
                continue;
            }

            if (
                tbItm.status === TbItmStatus.CLOSED ||
                tbItm.status === TbItmStatus.CLOSED_GUARDED
            ) {
                _total += tbItm.asphaltTS ?? 0;
            }
        }

        return _total;
    }

    get totalFS() {
        let _total = 0;

        for (const tbItm of this.myTbItms) {
            if (tbItm.surface !== TbItmSurfaces.ASPHALT) {
                continue;
            }

            if (tbItm.status === TbItmStatus.ASPHALT_TS) {
                _total += tbItm.asphaltFS ?? 0;
            }
        }

        return _total;
    }

    get comments() {
        return this._comments;
    }

    get commentTss() {
        return keys(this._comments);
    }

    setGarduri(val) {
        set(this, ["_tools", CmdTools.GARDURI.key], val || 0);
    }
    setTalpi(val) {
        set(this, ["_tools", CmdTools.TALPI.key], val || 0);
    }
    setBarci(val) {
        set(this, ["_tools", CmdTools.BARCI.key], val || 0);
    }
    setLumini(val) {
        set(this, ["_tools", CmdTools.LUMINI.key], val || 0);
    }
    setSemne(val) {
        set(this, ["_tools", CmdTools.SEMNE.key], val || 0);
    }
    setPlaciOtel(val) {
        set(this, ["_tools", CmdTools.PLACI_OTEL.key], val || 0);
    }
    setToolsComment(val) {
        set(this, ["_tools", "com"], val || "");
    }
    setSts(val) {
        this._sts = val;
    }
    setFullAdrs(val) {
        this._fullAdrs = val;
    }
    setCtrIdent(val) {
        this._ctrIdent = val;
    }
    setFullLoadStarted() {
        this._fullLoadStarted = Date.now();
    }

    setTb(val) {
        this._tb = val;
    }
    setMn(val) {
        this._m = val;
    }
    setCu(val) {
        this._cu = val;
    }
    setGf(val) {
        this._gf = val;
    }
    setApl(val) {
        this._apl = val;
    }
    setFo(val) {
        this._fo = val;
    }
    setNeedVAO(val) {
        this._needVAO = val;
    }

    setRegion(val) {
        this._region = val;
    }
    setClnt(val) {
        this._clnt = val;
    }
    setTyp(val) {
        this._typ = val;
    }
    setEingang(val) {
        this._eingang = val;
    }

    upsertComment(srvJson) {
        let _com = this._comments[srvJson.t];
        if (!_com) {
            _com = new CmdComment();
            this._comments[srvJson.t] = _com;
        }

        _com.upsertComment(srvJson);
    }

    fromAdrsPatch(patchData) {
        //console.log("fromAdrsPatch(patchData)", patchData);

        this._fullAdrs = patchData.adrs;
        this._adrsInpTyp = patchData.adrs_inp_type;

        this._street = patchData.adrs_street;
        this._nr = patchData.adrs_nr;
        this._city = patchData.adrs_city;
        this._zip = patchData.adrs_zip;

        this._lat = patchData.lat;
        this._lng = patchData.lng;
    }

    fromSrv(srvJson) {
        //console.log("fromSrv(srvJson)", srvJson);

        this._id = srvJson.id;
        this._sts = srvJson.sts;
        this._ctrIdent = srvJson.sm;
        this._comment = srvJson.comment;

        this._fullAdrs = srvJson.adrs;
        this._adrsInpTyp = srvJson.adrs_input_type;

        this._street = srvJson.adrs_street;
        this._nr = srvJson.adrs_nr;
        this._zip = srvJson.adrs_zip;
        this._city = srvJson.adrs_city;

        this._lat = srvJson.lat;
        this._lng = srvJson.lng;

        this._needVAO = srvJson.need_vao;
        this._region = srvJson.region;
        this._clnt = srvJson.client;
        this._typ = srvJson.typ;
        this._tb = srvJson.tb;
        this._m = srvJson.mn;
        this._fo = srvJson.fo;
        this._cu = srvJson.cu;
        this._gf = srvJson.gf;
        this._apl = srvJson.apl;
        this._eingang = srvJson.eingang;

        this._aaz = srvJson.aaz;
        this._faz = srvJson.faz;
        this._rb = srvJson.rb;

        this._sync = srvJson.sync;
        this._tools = srvJson.tools || {};

        this._clntAspId = srvJson.clnt_asp_id;
        this._billingUsrId = srvJson.billing_usr_id;
        this._dispoUsrId = srvJson.dispo_usr_id;

        this._bfName = srvJson.bf_name;
        this._bfMail = srvJson.bf_mail;
        this._bfTel = srvJson.bf_tel;

        this._lastLoad = Date.now();
    }
}
