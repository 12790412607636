import { isNil, isString, isEmpty, isNumber } from "lodash";

export const nrIsEmpty = (val) => {
    if (isNil(val)) {
        return true;
    }
    if (isString(val)) {
        return val.trim().length === 0;
    }
    if (isNumber(val) || val === true || val === false) {
        return false;
    }

    return isEmpty(val);
};

export const addGETParams = (url, params) => {
    if (nrIsEmpty(params)) {
        return url;
    }

    const _getParams = [];

    for (const [key, val] of Object.entries(params)) {
        if (nrIsEmpty(val)) {
            continue;
        }

        _getParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
        );
    }

    if (_getParams.length === 0) {
        return url;
    }

    return url + "?" + _getParams.join("&");
};

export const prepCsvVal = (val) => {
    if (!val) {
        return "";
    }

    return '"' + val.replace(/["]/g, '""') + '"';
};
