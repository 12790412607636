import { makeObservable, observable, action, computed } from "mobx";

export class ClntAsp {
    _id = "";
    _name = "";
    _tel = "";
    _mail = "";
    _chgd = null;

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _name: observable,
            _tel: observable,
            _mail: observable,
            _chgd: observable,

            id: computed,
            name: computed,
            tel: computed,
            mail: computed,

            fromSrv: action.bound,
        });
    }

    get id() {
        return this._id;
    }
    get name() {
        return this._name;
    }
    get tel() {
        return this._tel;
    }
    get mail() {
        return this._mail;
    }

    fromSrv(srvJson) {
        //console.log("ClntAsp::fromSrv(srvJson)", srvJson);

        if (this._chgd === srvJson.chgd) {
            return;
        }

        this._id = srvJson.id;
        this._name = srvJson.name;
        this._tel = srvJson.tel;
        this._mail = srvJson.mail;
        this._chgd = srvJson.chgd;

        this._lastLoad = Date.now();
    }
}
