import { observer } from "mobx-react-lite";
import { requireUserById } from "../../store/users/actions";

function UserInfo({ uid, shortInfo = 0 }) {
    if (!uid) {
        return "";
    }

    const _usr = requireUserById(uid);

    return fmtUsrInfo(_usr, shortInfo) || uid;
}

export function fmtUsrInfo(usr, shortInfo = 0) {
    if (!usr) {
        return "";
    }

    if (usr.fname && usr.lname) {
        // we have both firstName and lastName set
        if (shortInfo === 2) {
            return `${usr.fname.substr(0, 1)}${usr.lname.substr(0, 1)}`;
        } else if (shortInfo === 1) {
            return `${usr.fname.substr(0, 1)}. ${usr.lname}`;
        } else {
            return `${usr.fname} ${usr.lname}`;
        }
    }

    if (usr.fname || usr.lname) {
        if (usr.fname) {
            return usr.fname;
        }
        if (usr.lname) {
            return usr.lname;
        }
    }

    if (usr.email) {
        return usr.email;
    }

    return usr.id;
}

export default observer(UserInfo);
