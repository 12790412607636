import { BugCrash } from "../../../modules/bug-crash";
import { addError } from "../../error-info/actions";
import { doneTask, startTask } from "../../work-progress/actions";
import root from "../../";
import { runInAction } from "mobx";
import { getClntAsps, saveClntAsp } from "../../../modules/api-service";
import { ClntAsp } from "../../../modules/cls/clnt-asp";
import { getUuid } from "../../../modules/id-generator";

export const requireClntAspById = (id) => {
    let _clntAsp = root.clntAsps[id];
    if (!_clntAsp) {
        _clntAsp = new ClntAsp(id);
        root.clntAsps[id] = _clntAsp;
    }

    return _clntAsp;
};

export const actLoadClntAsps = async () => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadClntAsps`);

        const _srvData = await getClntAsps();
        //console.log("_srvData", _srvData);

        if (_srvData) {
            runInAction(() => {
                for (const _srvItm of _srvData) {
                    const _itm = requireClntAspById(_srvItm.id);
                    _itm.fromSrv(_srvItm);
                }
            });
        }
    } catch (err) {
        addError({
            lbl: "load_clnt_asps",
        });
        BugCrash.notifyExt("actLoadClntAsps", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actSaveClntAsp = async (clntAspId, data) => {
    let _wpId;

    try {
        _wpId = startTask(`actSaveClntAsp_${clntAspId}`);

        const _id = clntAspId === "new" ? getUuid() : clntAspId;

        await saveClntAsp(_id, data);
        await actLoadClntAsps();

        return _id;
    } catch (err) {
        addError({
            lbl: "save_clnt_asp",
        });
        BugCrash.notifyExt("actSaveClntAsp", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
