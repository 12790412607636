import { sortBy } from "lodash";
import { observable, values } from "mobx";

export default observable({
    get sortedUsrs() {
        const _usrs = values(this);

        return sortBy(_usrs, ["lname", "fname", "email"]);
    },

    get sortedUsrIds() {
        return this.sortedUsrs.map((it) => it.id);
    },

    get sortedDispoUsrIds() {
        return this.sortedUsrs.filter((it) => it.rights & 1).map((it) => it.id);
    },
    get billingDispoUsrIds() {
        return this.sortedUsrs.filter((it) => it.rights & 2).map((it) => it.id);
    },
});
