import { runInAction } from "mobx";
import root from "../../";
import set from "lodash/set";
import { getCmds } from "../../../modules/api-service";
import { requireCmdById } from "../../cmds/actions";
import { requireTbItmById } from "../../tb-itms/actions";
import { actSyncCmdTbItmIds } from "../../cmd-tb-itms/actions";
import { entries } from "lodash";
import { doneTask, startTask } from "../../work-progress/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { addError } from "../../error-info/actions";
import dayjs from "dayjs";
import { requireVaoById } from "../../vaos/actions";
import { actSyncCmdVaoIds } from "../../cmd-vaos/actions";

export const actResetFilters = () => {
    runInAction(() => {
        root.uiLstCmds.materiale = "";
        root.uiLstCmds.fltSts = "";

        root.uiLstCmds.fltNeedVAO = "";
        root.uiLstCmds.fltVAO1 = "";
        root.uiLstCmds.fltVAO2 = "";
        root.uiLstCmds.fltVAOx = dayjs().format("YYYY-MM-DD");

        root.uiLstCmds.fltRegion = "";
        root.uiLstCmds.fltClnt = "";
        root.uiLstCmds.fltTxt = "";
        root.uiLstCmds.fltTB = "";

        root.uiLstCmds.fltM = "";
        root.uiLstCmds.fltFO = "";
        root.uiLstCmds.fltTyp = "";
        root.uiLstCmds.fltCmdsLstId = "";
        root.uiLstCmds.fltBillingUsrId = "";
        root.uiLstCmds.fltDispoUsrId = "";

        root.uiLstCmds.tbSurfaces.length = 0;
        root.uiLstCmds.tbSts.length = 0;

        root.uiLstCmds.showVAO = true;
        root.uiLstCmds.showComment = true;

        root.uiLstCmds.fltEingangAb = null;
        root.uiLstCmds.fltEingangBis = null;

        root.uiLstCmds.fltClntAspId = "";

        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
    });

    actLoadCmds().catch(console.error);
};

export const actChgViewMode = (val) => {
    runInAction(() => {
        root.uiLstCmds.viewMode = val;
        root.uiLstCmds.nr = 0;

        if (val === "lst") {
            root.uiLstCmds.pg = 1;
            root.uiLstCmds.ids.length = 0;
        } else {
            root.uiLstCmds.pgMap = 1;
            root.uiLstCmds.idsMap.length = 0;
        }
    });

    actLoadCmds().catch(console.error);
};

export const actChgShowVAO = (visible) => {
    runInAction(() => {
        root.uiLstCmds.showVAO = !!visible;
    });
};

export const actChgShowComment = (visible) => {
    runInAction(() => {
        root.uiLstCmds.showComment = !!visible;
    });
};

export const actChgFltNeedVAO = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltNeedVAO = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltTB = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltTB = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltM = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltM = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltFO = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltFO = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltRegion = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltRegion = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltTyp = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltTyp = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltSts = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltSts = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltClnt = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltClnt = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltTxt = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltTxt = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltVAO1 = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltVAO1 = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltVAO2 = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltVAO2 = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltVAOx = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
        root.uiLstCmds.fltVAOx = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgPg = (val) => {
    runInAction(() => {
        root.uiLstCmds.pg = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgPgMap = (val) => {
    runInAction(() => {
        root.uiLstCmds.pgMap = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgTbSurfaces = (val) => {
    runInAction(() => {
        root.uiLstCmds.tbSurfaces = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgTbSts = (val) => {
    runInAction(() => {
        root.uiLstCmds.tbSts = val;
    });

    actLoadCmds().catch(console.error);
};

export const actChgMateriale = (materiale) => {
    runInAction(() => {
        root.uiLstCmds.materiale = materiale;
    });

    actLoadCmds().catch(console.error);
};

export const actChgEingangAb = (ev) => {
    runInAction(() => {
        root.uiLstCmds.fltEingangAb = ev?.format("YYYY-MM-DD") || null;
    });

    actLoadCmds().catch(console.error);
};

export const actChgEingangBis = (ev) => {
    runInAction(() => {
        root.uiLstCmds.fltEingangBis = ev?.format("YYYY-MM-DD") || null;
    });

    actLoadCmds().catch(console.error);
};

export const actToggleSelId = (id) => {
    runInAction(() => {
        const idx = root.uiLstCmds.selIds.indexOf(id);
        if (idx > -1) {
            root.uiLstCmds.selIds.splice(idx, 1);
        } else {
            root.uiLstCmds.selIds.push(id);
        }
    });
};

export const actToggleShowSelCmds = () => {
    runInAction(() => {
        root.uiLstCmds.showSelCmds = !root.uiLstCmds.showSelCmds;
    });
};

export const actEmptySelCmds = () => {
    runInAction(() => {
        root.uiLstCmds.selIds.length = 0;
        root.uiLstCmds.showSelCmds = false;
    });
};

export const actChgFltCmdsLstId = (val) => {
    runInAction(() => {
        root.uiLstCmds.fltCmdsLstId = val;
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltClntAspId = (val) => {
    runInAction(() => {
        root.uiLstCmds.fltClntAspId = val;
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltBillingUsrId = (val) => {
    runInAction(() => {
        root.uiLstCmds.fltBillingUsrId = val;
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
    });

    actLoadCmds().catch(console.error);
};

export const actChgFltDispoUsrId = (val) => {
    runInAction(() => {
        root.uiLstCmds.fltDispoUsrId = val;
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
    });

    actLoadCmds().catch(console.error);
};

export const actChgOrdByCmdsLst = (val) => {
    runInAction(() => {
        root.uiLstCmds.ordBy = val;
        root.uiLstCmds.pg = 1;
        root.uiLstCmds.pgMap = 1;
    });

    actLoadCmds().catch(console.error);
};

export const actLoadCmds = async () => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadCmds`);

        const options = {
            ordBy: root.uiLstCmds.ordBy,
        };

        if (root.uiLstCmds.fltNeedVAO) {
            set(options, ["filter", "needVAO"], root.uiLstCmds.fltNeedVAO);
        }
        if (root.uiLstCmds.materiale) {
            set(options, ["filter", "mat"], root.uiLstCmds.materiale);
        }
        if (root.uiLstCmds.fltSts) {
            set(options, ["filter", "sts"], root.uiLstCmds.fltSts);
        }

        if (root.uiLstCmds.fltCmdsLstId) {
            set(options, ["filter", "cmdsLstId"], root.uiLstCmds.fltCmdsLstId);
        }
        if (root.uiLstCmds.fltClntAspId) {
            set(options, ["filter", "clntAspId"], root.uiLstCmds.fltClntAspId);
        }
        if (root.uiLstCmds.fltBillingUsrId) {
            set(
                options,
                ["filter", "billingUsrId"],
                root.uiLstCmds.fltBillingUsrId
            );
        }
        if (root.uiLstCmds.fltDispoUsrId) {
            set(
                options,
                ["filter", "dispoUsrId"],
                root.uiLstCmds.fltDispoUsrId
            );
        }

        if (root.uiLstCmds.fltEingangAb) {
            set(options, ["filter", "eingangAb"], root.uiLstCmds.fltEingangAb);
        }

        if (root.uiLstCmds.fltEingangBis) {
            set(
                options,
                ["filter", "eingangBis"],
                root.uiLstCmds.fltEingangBis
            );
        }

        if (root.uiLstCmds.fltNeedVAO === "y") {
            if (root.uiLstCmds.fltVAO1) {
                set(options, ["filter", "VAO1"], root.uiLstCmds.fltVAO1);
            }
            if (root.uiLstCmds.fltVAO2) {
                set(options, ["filter", "VAO2"], root.uiLstCmds.fltVAO2);
            }
            if (root.uiLstCmds.fltVAOx) {
                set(options, ["filter", "VAOx"], root.uiLstCmds.fltVAOx);
            }
        }

        if (root.uiLstCmds.fltRegion) {
            set(options, ["filter", "region"], root.uiLstCmds.fltRegion);
        }
        if (root.uiLstCmds.fltClnt) {
            set(options, ["filter", "clnt"], root.uiLstCmds.fltClnt);
        }
        if (root.uiLstCmds.fltTxt) {
            set(options, ["filter", "txt"], root.uiLstCmds.fltTxt);
        }
        if (root.uiLstCmds.fltTB) {
            set(options, ["filter", "tb"], root.uiLstCmds.fltTB);

            if (root.uiLstCmds.fltTB === "y") {
                if (root.uiLstCmds.tbSurfaces?.length > 0) {
                    set(
                        options,
                        ["filter", "tbSurfaces"],
                        root.uiLstCmds.tbSurfaces
                    );
                }
                if (root.uiLstCmds.tbSts?.length > 0) {
                    set(options, ["filter", "tbSts"], root.uiLstCmds.tbSts);
                }
            }
        }
        if (root.uiLstCmds.fltM) {
            set(options, ["filter", "m"], root.uiLstCmds.fltM);
        }
        if (root.uiLstCmds.fltTyp) {
            set(options, ["filter", "typ"], root.uiLstCmds.fltTyp);
        }
        if (root.uiLstCmds.fltFO) {
            set(options, ["filter", "fo"], root.uiLstCmds.fltFO);
        }

        options["pg"] =
            root.uiLstCmds.viewMode === "lst"
                ? root.uiLstCmds.pg
                : root.uiLstCmds.pgMap;
        options["rpg"] =
            root.uiLstCmds.viewMode === "lst"
                ? root.uiLstCmds.rpg
                : root.uiLstCmds.rpgMap;

        const _cmdsResp = await getCmds(options);

        //console.log("_cmdsResp", _cmdsResp);

        runInAction(() => {
            root.uiLstCmds.ids.length = 0;
            root.uiLstCmds.idsMap.length = 0;

            const _ids =
                root.uiLstCmds.viewMode === "lst"
                    ? root.uiLstCmds.ids
                    : root.uiLstCmds.idsMap;

            for (const _srvItm of _cmdsResp.itms) {
                _ids.push(_srvItm.id);

                const _cmd = requireCmdById(_srvItm.id);
                _cmd.fromSrv(_srvItm);
            }

            root.uiLstCmds.nr = _cmdsResp.nr;

            // START SYNC TB-ITMS FROM RESPONSE
            if (_cmdsResp.tbItms?.length > 0) {
                const _grpByCmdId = {};

                for (const _srvItm of _cmdsResp.tbItms) {
                    const _tbItm = requireTbItmById(_srvItm.id);
                    _tbItm.fromSrv(_srvItm);

                    if (!_grpByCmdId[_srvItm.cmd_id]) {
                        _grpByCmdId[_srvItm.cmd_id] = [];
                    }

                    _grpByCmdId[_srvItm.cmd_id].push(_srvItm.id);
                }

                //console.log("_grpByCmdId", _grpByCmdId);

                for (const [cId, tbItmIds] of entries(_grpByCmdId)) {
                    actSyncCmdTbItmIds(cId, tbItmIds);
                }
            }
            // END SYNC TB-ITMS FROM RESPONSE

            // START SYNC VAOS FROM RESPONSE
            if (_cmdsResp.vaos?.length > 0) {
                const _grpByCmdId = {};

                for (const _srvItm of _cmdsResp.vaos) {
                    const _vao = requireVaoById(_srvItm.id);
                    _vao.fromSrv(_srvItm);

                    if (!_grpByCmdId[_srvItm.cmd_id]) {
                        _grpByCmdId[_srvItm.cmd_id] = [];
                    }

                    _grpByCmdId[_srvItm.cmd_id].push(_srvItm.id);
                }

                //console.log("_grpByCmdId", _grpByCmdId);

                for (const [cId, vaoIds] of entries(_grpByCmdId)) {
                    actSyncCmdVaoIds(cId, vaoIds);
                }
            }
            // END SYNC VAOS FROM RESPONSE
        });
    } catch (err) {
        addError({
            lbl: "load_lst_cmds",
        });
        BugCrash.notifyExt("actLoadCmds", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
