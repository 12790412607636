import { runInAction } from "mobx";
import root from "../../";
import { requireTbItmById } from "../../tb-itms/actions";

export const getNextCmdTbItmNrByType = (cmdId, typ) => {
    const _tbItmIdsOfCmd = root.cmdTbItms[cmdId];
    if (!_tbItmIdsOfCmd) {
        return 1;
    }

    let _nextNr = 1;

    for (const _tbItmId of _tbItmIdsOfCmd) {
        const _tbItm = requireTbItmById(_tbItmId);

        if (Number(_tbItm.typ) === Number(typ)) {
            if (_tbItm.nr >= _nextNr) {
                _nextNr = _tbItm.nr + 1;
            }
        }
    }

    return _nextNr;
};

export const actSyncCmdTbItmIds = (cmdId, tbItmIds) => {
    runInAction(() => {
        let _cmdTbItmIds = root.cmdTbItms[cmdId];
        if (_cmdTbItmIds) {
            // empty array of ids
            _cmdTbItmIds.length = 0;
        }

        for (const tbItmId of tbItmIds) {
            actAddTbItmId2Cmd(cmdId, tbItmId);
        }
    });
};

export const actAddTbItmId2Cmd = (cmdId, tbItmId) => {
    runInAction(() => {
        let _cmdTbItmIds = root.cmdTbItms[cmdId];
        if (!_cmdTbItmIds) {
            root.cmdTbItms[cmdId] = [];
            _cmdTbItmIds = root.cmdTbItms[cmdId];
        }

        if (!_cmdTbItmIds.includes(tbItmId)) {
            _cmdTbItmIds.push(tbItmId);
        }
    });
};
