import { runInAction } from "mobx";
import root from "../../";

import { TbItm } from "../../../modules/cls/tb-itm";
import {
    delTbItm,
    getOneCmdTbItms,
    getTbItmById,
    saveTbItm,
} from "../../../modules/api-service";
import { entries, get } from "lodash";
import {
    actAddTbItmId2Cmd,
    actSyncCmdTbItmIds,
    getNextCmdTbItmNrByType,
} from "../../cmd-tb-itms/actions";
import { doneTask, startTask } from "../../work-progress/actions";
import { addError } from "../../error-info/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { getUuid } from "../../../modules/id-generator";

/**
 * @returns {TbItm}
 */
export const requireTbItmById = (id) => {
    let _itm = root.tbItms[id];
    if (!_itm) {
        _itm = new TbItm(id);
        root.tbItms[id] = _itm;
    }

    return _itm;
};

export const actLoadTbItmById = async (tbItmId) => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadTbItm_${tbItmId}`);

        const _srvData = await getTbItmById(tbItmId);
        // console.log("_srvData", _srvData);

        if (_srvData) {
            runInAction(() => {
                const _tbItm = requireTbItmById(tbItmId);
                _tbItm.fromSrv(_srvData);
                actAddTbItmId2Cmd(_srvData.cmd_id, _srvData.id);
            });
        }
    } catch (err) {
        addError({
            lbl: "load_tb_itm",
        });
        BugCrash.notifyExt("actLoadTbItm", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actDelTbItm = async (cmdId, tbItmId) => {
    let _wpId;

    try {
        _wpId = startTask(`actDelTbItm_${cmdId}_${tbItmId}`);

        await delTbItm(tbItmId);
        await actLoadOneCmdTbItms(cmdId);
    } catch (err) {
        addError({
            lbl: "del_tb_itm",
        });
        BugCrash.notifyExt("actDelTbItm", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actSaveTbItm = async (cmdId, tbItm) => {
    let _wpId;

    try {
        _wpId = startTask(`actSaveTbItm_${cmdId}_${tbItm.id}`);

        const _data4Srv = tbItm.toSrv;
        _data4Srv["cmd_id"] = cmdId;

        //console.log("must save", _data4Srv);

        await saveTbItm(tbItm.id, _data4Srv);
        await actLoadTbItmById(tbItm.id);

        return true; // No exceptions were thrown, so return true
    } catch (err) {
        addError({
            lbl: "save_tb_itm",
        });
        BugCrash.notifyExt("actSaveTbItm", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actLoadOneCmdTbItms = async (cmdId) => {
    try {
        const _srvResp = await getOneCmdTbItms(cmdId);

        runInAction(() => {
            const _grpByCmdId = {};
            _grpByCmdId[cmdId] = [];

            for (const _srvItm of get(_srvResp, ["itms"], [])) {
                const _tbItm = requireTbItmById(_srvItm.id);
                _tbItm.fromSrv(_srvItm);

                if (!_grpByCmdId[_srvItm.cmd_id]) {
                    _grpByCmdId[_srvItm.cmd_id] = [];
                }

                _grpByCmdId[_srvItm.cmd_id].push(_srvItm.id);
            }

            for (const [cId, tbItmIds] of entries(_grpByCmdId)) {
                actSyncCmdTbItmIds(cId, tbItmIds);
            }
        });
    } catch (err) {
        console.error(err);
    }
};

export const actNewTbItm = (cmdId, typ) => {
    const _newId = getUuid();

    runInAction(() => {
        const _newItm = requireTbItmById(_newId);

        _newItm.setNew(true);
        _newItm.setTyp(typ);
        _newItm.setNr(getNextCmdTbItmNrByType(cmdId, typ));

        actAddTbItmId2Cmd(cmdId, _newId);
    });

    return _newId;
};
