import dayjs from "dayjs";
import { observable } from "mobx";
import { DEFAULT_PG_SIZE } from "../../modules/const";
import { TbItmStatus, TbItmSurfaces } from "../../modules/cls/tb-itm";
import { orderBy } from "lodash";
import { requireCmdById } from "../cmds/actions";

export default observable({
    viewMode: "lst",

    fltSts: "",
    fltNeedVAO: "",
    fltVAO1: "",
    fltVAO2: "",
    fltVAOx: dayjs().format("YYYY-MM-DD"),
    fltRegion: "",
    fltClnt: "",
    fltTxt: "",
    fltTB: "",
    tbSurfaces: [],
    tbSts: [],
    fltM: "",
    fltFO: "",
    fltTyp: "",
    showVAO: true,
    showComment: true,

    fltCmdsLstId: "",
    fltClntAspId: "",

    fltDispoUsrId: "",
    fltBillingUsrId: "",

    fltEingangAb: null,
    fltEingangBis: null,

    ordBy: "",

    materiale: "",

    ids: [],
    pg: 1,
    rpg: DEFAULT_PG_SIZE,

    idsMap: [],
    pgMap: 1,
    rpgMap: 100,

    selIds: [],
    showSelCmds: false,

    nr: 0,

    get sortedIdsMap() {
        if (
            this.tbSurfaces?.length !== 1 ||
            !this.tbSurfaces.includes(TbItmSurfaces.ASPHALT)
        ) {
            return this.idsMap;
        }

        const _sortByFS = this.tbSts?.includes(TbItmStatus.ASPHALT_TS);
        const _qtyById = {};

        for (const _id of this.idsMap) {
            const _cmd = requireCmdById(_id);
            if (!_id) {
                continue;
            }

            _qtyById[_id] = _sortByFS ? _cmd.totalFS : _cmd.totalTS;
        }

        return orderBy(this.idsMap, (id) => _qtyById[id], "desc");
    },
});
