import { action, computed, makeObservable, observable } from "mobx";

export class CmdComment {
    _ts = "";
    _uid = "";
    _comment = ""

    constructor() {
        makeObservable(this, {
            _ts: observable,
            _uid: observable,
            _comment: observable,

            ts: computed,
            uid: computed,
            comment: computed,

            setTs: action.bound,
            setUid: action.bound,
            setComment: action.bound,
        })
    }

    get ts() {
        return this._ts;
    }
    get uid() {
        return this._uid;
    }
    get comment() {
        return this._comment;
    }

    setTs(val) {
        this._ts = val;
    }
    setUid(val) {
        this._uid = val;
    }
    setComment(val) {
        this._comment = val;
    }

    upsertComment(srvJson) {
        if (this._ts !== srvJson.t) {
            this._ts = srvJson.t
        }
        if (this._uid !== srvJson.u) {
            this._uid = srvJson.u
        }
        if (this._comment !== srvJson.c) {
            this._comment = srvJson.c
        }
    }
}