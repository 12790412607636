import { action, computed, makeObservable, observable } from "mobx";

export class Vao {
    _id = "";
    _new = false;

    _reqDt = "";
    _reqEdtr = "";
    _reqSentAt = "";

    _startDt = null;
    _endDt = null;

    _costs = "";
    _comment = "";

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _new: observable,

            _reqDt: observable,
            _reqEdtr: observable,
            _reqSentAt: observable,

            _startDt: observable,
            _endDt: observable,

            _costs: observable,
            _comment: observable,

            id: computed,
            isNew: computed,
            reqDt: computed,
            reqEdtr: computed,
            reqSentAt: computed,

            startDt: computed,
            endDt: computed,

            costs: computed,
            comment: computed,

            setNew: action.bound,
            setReqDt: action.bound,
            setReqEdtr: action.bound,
            setReqSentAt: action.bound,

            setStartDt: action.bound,
            setEndDt: action.bound,

            setCosts: action.bound,
            setComment: action.bound,

            fromSrv: action.bound,
        });
    }

    get id() {
        return this._id;
    }

    get isNew() {
        return this._new;
    }

    get reqDt() {
        return this._reqDt;
    }
    get reqEdtr() {
        return this._reqEdtr;
    }
    get reqSentAt() {
        return this._reqSentAt;
    }

    get startDt() {
        return this._startDt;
    }
    get endDt() {
        return this._endDt;
    }

    get costs() {
        return this._costs;
    }
    get comment() {
        return this._comment;
    }

    setNew(val) {
        if (this._new !== val) {
            this._new = val;
        }
    }
    setReqDt(val) {
        if (this._reqDt !== val) {
            this._reqDt = val;
        }
    }
    setReqEdtr(val) {
        if (this._reqEdtr !== val) {
            this._reqEdtr = val;
        }
    }
    setReqSentAt(val) {
        if (this._reqSentAt !== val) {
            this._reqSentAt = val;
        }
    }

    setStartDt(val) {
        if (this._startDt !== val) {
            this._startDt = val;
        }
    }
    setEndDt(val) {
        if (this._endDt !== val) {
            this._endDt = val;
        }
    }

    setCosts(val) {
        if (this._costs !== val) {
            this._costs = val;
        }
    }
    setComment(val) {
        if (this._comment !== val) {
            this._comment = val;
        }
    }

    fromSrv(srvJson) {
        // console.log("Vao::fromSrv(srvJson)", srvJson);

        this._id = srvJson.id;

        this._reqDt = srvJson.req_dt;
        this._reqEdtr = srvJson.req_edtr;
        this._reqSentAt = srvJson.req_sent_at;

        this._startDt = srvJson.start_dt;
        this._endDt = srvJson.end_dt;

        this._costs = srvJson.costs;
        this._comment = srvJson.comment;
    }
}
