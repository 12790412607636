import { runInAction } from "mobx";
import root from "../../";
import { doneTask, startTask } from "../../work-progress/actions";
import { addError } from "../../error-info/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { actLoadOneCmdVaos } from "../../vaos/actions";
import { patchCmdVao } from "../../../modules/api-service";

export const actAddVaoId2Cmd = (cmdId, vaoId) => {
    runInAction(() => {
        let _cmdVaoIds = root.cmdVaos[cmdId];
        if (!_cmdVaoIds) {
            root.cmdVaos[cmdId] = [];
            _cmdVaoIds = root.cmdVaos[cmdId];
        }

        if (!_cmdVaoIds.includes(vaoId)) {
            _cmdVaoIds.push(vaoId);
        }
    });
};

export const actSyncCmdVaoIds = (cmdId, vaoIds) => {
    runInAction(() => {
        let _cmdVaoIds = root.cmdVaos[cmdId];
        if (_cmdVaoIds) {
            // empty array of ids
            _cmdVaoIds.length = 0;
        }

        for (const vaoId of vaoIds) {
            actAddVaoId2Cmd(cmdId, vaoId);
        }
    });
};

export const actPatchCmdVao = async (cmdId, vaoId, patchData) => {
    let _wpId;

    try {
        _wpId = startTask(`actPatchCmdVao_${cmdId}_${vaoId}`);

        patchData.cmd_id = cmdId;

        await patchCmdVao(vaoId, patchData);
        await actLoadOneCmdVaos(cmdId);

        return true;
    } catch (err) {
        addError({
            lbl: "save_vao",
        });
        BugCrash.notifyExt("actPatchCmdVao", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
