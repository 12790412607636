import auth from "./auth";
import clnts from "./clnts";
import clntAsps from "./clnt-asps";
import clogs from "./clogs";

import cmdTypes from "./cmd-types";
import cmdClogs from "./cmd-clogs";
import cmdSts from "./cmd-sts";
import cmds from "./cmds";
import fltLists from "./flt-lists";
import syncRpts from "./sync-rpts";
import regions from "./regions";
import session from "./session";
import uiLstCmds from "./ui-lst-cmds";
import uiLstSyncRpts from "./ui-lst-sync-rpts";
import uiMngtFltLsts from "./ui-mngt-flt-lists";
import uiLstCmdsFltLstCmds from "./ui-lst-cmds-flt-lst-cmds";

import vaos from "./vaos";
import cmdVaos from "./cmd-vaos";

import vaoReqs from "./vao-reqs";
import cmdVaoReqs from "./cmd-vao-reqs";
import vaoIntvs from "./vao-intvs";
import cmdVaoIntvs from "./cmd-vao-intvs";
import tbItms from "./tb-itms";
import cmdTbItms from "./cmd-tb-itms";
import errorInfo from "./error-info";

import upBatches from "./up-batches";
import upItms from "./up-itms";

import users from "./users";
import workProgress from "./work-progress";

const store = {
    auth,
    clnts,
    clntAsps,
    clogs,
    cmdTypes,
    cmdClogs,
    cmdSts,
    cmds,
    errorInfo,
    fltLists,
    regions,
    session,
    syncRpts,
    uiLstCmds,
    uiLstSyncRpts,
    uiMngtFltLsts,
    uiLstCmdsFltLstCmds,
    vaoReqs,
    cmdVaoReqs,
    vaoIntvs,
    cmdVaoIntvs,
    tbItms,
    cmdTbItms,

    upBatches,
    upItms,

    users,
    vaos,
    cmdVaos,
    workProgress,
};

export default store;
