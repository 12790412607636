import { Vao } from "../../../modules/cls/vao";
import root from "../../";
import { doneTask, startTask } from "../../work-progress/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { addError } from "../../error-info/actions";
import { runInAction } from "mobx";
import {
    delVao,
    getOneCmdVaos,
    getVaoById,
} from "../../../modules/api-service";
import { actAddVaoId2Cmd, actSyncCmdVaoIds } from "../../cmd-vaos/actions";
import { entries, get } from "lodash";

/**
 * @returns {Vao}
 */
export const requireVaoById = (id) => {
    let _itm = root.vaos[id];
    if (!_itm) {
        _itm = new Vao(id);
        root.vaos[id] = _itm;
    }

    return _itm;
};

export const actLoadVaoById = async (vaoId) => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadVaoById_${vaoId}`);

        const _srvData = await getVaoById(vaoId);
        // console.log("_srvData", _srvData);

        if (_srvData) {
            runInAction(() => {
                const _vao = requireVaoById(vaoId);
                _vao.fromSrv(_srvData);
                actAddVaoId2Cmd(_srvData.cmd_id, _srvData.id);
            });
        }
    } catch (err) {
        addError({
            lbl: "load_vao",
        });
        BugCrash.notifyExt("actLoadVaoById", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actLoadOneCmdVaos = async (cmdId) => {
    try {
        const _srvResp = await getOneCmdVaos(cmdId);

        runInAction(() => {
            const _grpByCmdId = {};

            for (const _srvItm of get(_srvResp, ["itms"], [])) {
                const _vao = requireVaoById(_srvItm.id);
                _vao.fromSrv(_srvItm);

                if (!_grpByCmdId[_srvItm.cmd_id]) {
                    _grpByCmdId[_srvItm.cmd_id] = [];
                }

                _grpByCmdId[_srvItm.cmd_id].push(_srvItm.id);
            }

            for (const [cId, vaoIds] of entries(_grpByCmdId)) {
                actSyncCmdVaoIds(cId, vaoIds);
            }
        });
    } catch (err) {
        addError({
            lbl: "load_cmd_vaos",
        });
        BugCrash.notifyExt("actLoadOneCmdVaos", err);
    }
};

export const actDelVao = async (cmdId, vaoId) => {
    let _wpId;

    try {
        _wpId = startTask(`actDelVao_${cmdId}_${vaoId}`);

        await delVao(vaoId);
        await actLoadOneCmdVaos(cmdId);
    } catch (err) {
        addError({
            lbl: "del_vao",
        });
        BugCrash.notifyExt("actDelVao", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
