import { sortBy } from "lodash";
import { observable, values } from "mobx";

export default observable({
    get sortedAspIds() {
        const _asps = values(this);

        return sortBy(_asps, ["name"]).map((it) => it.id);
    },
});
